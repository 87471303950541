
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/anac-be-onboarding",
      function () {
        return require("private-next-pages/anac-be-onboarding.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/anac-be-onboarding"])
      });
    }
  